import { httpUpdate } from '../api/apiService'

export async function updateSessionOnReduxAndSendToServer(
  props,
  updatedSessionValue
) {
  const { updateSession } = props

  const result = await httpUpdate(
    `${process.env.apiUrl}update`,
    updatedSessionValue
  )
  if (result) {
    const { status, data } = result
    if (status === 200) {
      updateSession(updatedSessionValue)
    } else {
      const { modal, updateModal } = props

      const duplicateModal = { ...modal }
      const title = 'Errore'
      const { message } = data
      duplicateModal.title = title
      duplicateModal.body = message
      duplicateModal.visible = true

      updateModal(duplicateModal)
    }
  }
}
