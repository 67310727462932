import React from 'react'
import { connect } from 'react-redux'
import { updateSessionOnReduxAndSendToServer } from '../../../../../../../common'

import * as actionCreators from '../../../../../../../store/actions'
import './styles.scss'

export class GenericComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
    }
  }

  componentDidMount = async () => {
    this.setState({ initialized: true })
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    const { initialized } = this.state
    return initialized !== nextState.initialized
  }

  handleValueChanged = (value, value_idx, question_idx, block_idx) => {
    const { session } = this.props

    const sessionCopy = { ...session }
    const { blocks } = sessionCopy
    const block = blocks[block_idx]
    const { dynamic_data } = block
    const question = dynamic_data[question_idx]
    const { exclusive, values } = question

    if (exclusive) {
      for (let i = 0; i !== values.length; i += 1) {
        values[i].value = i === value_idx ? value : !value
      }
    } else {
      values[value_idx].value = value
    }

    updateSessionOnReduxAndSendToServer(this.props, sessionCopy)
  }

  renderValues = () => {
    return <div>Be creative here. Creativity is all you need!!</div>
  }
}

const mapStateToProps = state => {
  return {
    session: state.session,
  }
}

export default connect(mapStateToProps, actionCreators)(GenericComponent)
