import http from './httpService'

export async function httpPost(path, data) {
  try {
    const result = await http.post(path, data)
    return result
  } catch (e) {
    return e
  }
}

export async function httpUpdate(path, item) {
  try {
    const url = `${path}`
    const result = await http.put(url, item)
    return result
  } catch (e) {
    return e
  }
}

export async function httpGet(path) {
  try {
    const result = await http.get(path)
    return result
  } catch (e) {
    return e
  }
}
