import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../../store/actions'
import { GenericComponent } from '../generic_component'
import './styles.scss'

const classNames = require('classnames')

export class GenericListItemComponent extends GenericComponent {
  render() {
    const { data } = this.props
    const { element } = data
    const { prefix, text } = element

    return (
      <div className={classNames('row mb-3 pb-3 gy-2 border-bottom')}>
        <div className={classNames('col-12 col-sm-6 fw-bold')}>
          {`${prefix}. ${text}`}
        </div>
        {this.renderValues()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    session: state.session,
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(GenericListItemComponent)
