import React from 'react'
import { connect } from 'react-redux'

import Layout from '../layout'
import HomeComponent from '../components/home'

import * as actionCreators from '../store/actions'

export class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <HomeComponent />
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
  }
}

export default connect(mapStateToProps, actionCreators)(IndexPage)
