import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../store/actions'
import { GenericFullTextComponent } from '../_base/generic_full_text'
import './styles.scss'

const classNames = require('classnames')

export class TextWithInputComponent extends GenericFullTextComponent {
  renderValues = () => {
    const { data } = this.props
    const { element, block_idx, question_idx } = data
    const { values } = element

    return (
      <input
        type="text"
        defaultValue={values[0].value}
        style={{ minWidth: '100px', boxShadow: 'none' }}
        className={classNames('fw-bold mx-1 border-bottom rounded-0')}
        onBlur={e => {
          this.handleValueChanged(e.target.value, 0, question_idx, block_idx)
        }}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    session: state.session,
  }
}

export default connect(mapStateToProps, actionCreators)(TextWithInputComponent)
