import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'

import * as actionCreators from '../../../../../../store/actions'
import { GenericListItemComponent } from '../_base/generic_list_item'
import './styles.scss'

const classNames = require('classnames')

export class FreeTextComponent extends GenericListItemComponent {
  renderValues = () => {
    const { data } = this.props
    const { element, block_idx, question_idx } = data
    const { values } = element

    return (
      <div className={classNames('')}>
        {values.map((val, idx) => {
          const { value, placeholder } = val
          return (
            <textarea
              key={uuid()}
              defaultValue={value}
              style={{ width: '100%', boxShadow: 'inset 0px 0px 0px 1px #ddd' }}
              rows={5}
              placeholder={placeholder}
              className={classNames('m-1 p-3 rounded border-0')}
              onBlur={e => {
                this.handleValueChanged(
                  e.target.value,
                  idx,
                  question_idx,
                  block_idx
                )
              }}
            />
          )
        })}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    session: state.session,
  }
}

export default connect(mapStateToProps, actionCreators)(FreeTextComponent)
