import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'

import * as actionCreators from '../../../../../../store/actions'
import { GenericFullTextComponent } from '../_base/generic_full_text'
import './styles.scss'

const classNames = require('classnames')

export class TextWithSelectComponent extends GenericFullTextComponent {
  renderValues = () => {
    const { data } = this.props
    const { element, block_idx, question_idx } = data
    const { values } = element

    const options = values.length > 0 ? values[0].options : []
    const { value } = values[0]

    return (
      <select
        name={`select_${block_idx}_${question_idx}`}
        style={{ minWidth: '200px', textAlignLast: 'center' }}
        className={classNames(
          'fw-bold bg-white border-top-0 border-start-0 border-end-0 mx-2'
        )}
        onChange={e => {
          console.log('e.target.value', e.target.value)
          this.handleValueChanged(e.target.value, 0, question_idx, block_idx)
        }}
      >
        <option value="">SELECT</option>
        {options.map(opt => {
          return (
            <option key={uuid()} value={opt} selected={value === opt}>
              {opt}
            </option>
          )
        })}
      </select>
    )
  }
}

const mapStateToProps = state => {
  return {
    session: state.session,
  }
}

export default connect(mapStateToProps, actionCreators)(TextWithSelectComponent)
