import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'

import * as actionCreators from '../../../../../../store/actions'
import { GenericListItemComponent } from '../_base/generic_list_item'
import './styles.scss'

const classNames = require('classnames')

export class SentenceWithInputComponent extends GenericListItemComponent {
  renderValues = () => {
    const { data } = this.props
    const { element, block_idx, question_idx } = data
    const { values } = element

    return (
      <div className={classNames('')}>
        {values.map((val, idx) => {
          const {
            before_control,
            after_control,
            max_length,
            dynamic_class_names,
            value,
          } = val
          return (
            <span key={uuid()} className={classNames('me-1')}>
              <span>{before_control}</span>
              <input
                style={{
                  boxShadow: 'none',
                  width:
                    max_length !== undefined
                      ? `calc(24px * ${parseInt(max_length, 10)})`
                      : 'auto',
                }}
                className={classNames(
                  'fw-bold mx-1 border-bottom rounded-0',
                  dynamic_class_names
                )}
                type="text"
                maxLength={max_length}
                defaultValue={value}
                onBlur={e => {
                  this.handleValueChanged(
                    e.target.value,
                    idx,
                    question_idx,
                    block_idx
                  )
                }}
              />
              <span>{after_control}</span>
            </span>
          )
        })}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    session: state.session,
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(SentenceWithInputComponent)
