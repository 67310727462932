export function updateModal(modal) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_MODAL',
      modal,
    })
  }
}

export function resetModal() {
  return async dispatch => {
    dispatch({
      type: 'RESET_MODAL',
    })
  }
}
