/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import './styles.scss'

import texts from '../../../../translations/texts.json'
import { httpPost } from '../../../../api/apiService'

const classNames = require('classnames')

export class StartComponent extends React.Component {
  componentDidMount = async () => {}

  handleStartExaml = async () => {
    const { session } = this.props
    const { exam, student } = session

    const result = await httpPost(`${process.env.apiUrl}start`, {
      exam_id: exam.id,
      exam_password: exam.password,
      student_email: student.email,
    })
    if (result) {
      const { status, data } = result
      if (status === 200) {
        const { updateSession } = this.props
        const updatedSessionValue = { ...data }
        await updateSession(updatedSessionValue)
      } else {
        const { modal, updateModal } = this.props

        const duplicateModal = { ...modal }
        const title = 'Errore'
        const { message } = data
        duplicateModal.title = title
        duplicateModal.body = message
        duplicateModal.visible = true

        updateModal(duplicateModal)
      }
    }
  }

  render() {
    const { session } = this.props
    const { student } = session
    const { first_name } = student

    return (
      <div className={classNames({ start_component: true })}>
        <div
          className={classNames(
            { start_exam: true },
            'container-fluid px-0 position-absolute top-50 d-flex flex-column justify-content-center align-items-center'
          )}
        >
          <div className={classNames('my-2 p-0 mx-2 mx-sm-5 text-center')}>
            {`${texts.it.hello} ${first_name} ${texts.it.click_to_start}`}
          </div>

          <div
            className={classNames('my-2 btn btn-primary')}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.handleStartExaml()
            }}
            onKeyPress={() => {
              this.handleStartExaml()
            }}
          >
            {texts.it.start}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    modal: state.modal,
    session: state.session,
  }
}

export default connect(mapStateToProps, actionCreators)(StartComponent)
