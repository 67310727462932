/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../store/actions'
import './styles.scss'

import texts from '../../translations/texts.json'

const classNames = require('classnames')

export class ModalComponent extends React.Component {
  componentDidMount = async () => {}

  handleCloseModal = () => {
    const { resetModal } = this.props
    resetModal()
  }

  render() {
    const { modal } = this.props
    const { visible, title, body } = modal

    return (
      <div
        className={classNames({ modal: true, fade: true, show: visible })}
        style={{
          display: visible === true ? 'block' : 'none',
          backgroundColor: '#00000026',
        }}
      >
        <div className={classNames('modal-dialog modal-dialog-centered')}>
          <div
            className={classNames(
              'modal-content modal-content border-0 shadow-lg'
            )}
          >
            <div className={classNames('modal-header')}>
              <h5 className={classNames('modal-title')}>{title}</h5>
              <div
                className={classNames('btn-close')}
                role="button"
                tabIndex={0}
                onClick={() => this.handleCloseModal()}
                onKeyPress={() => this.handleCloseModal()}
              />
            </div>
            <div className={classNames('modal-body')}>
              <p>{body}</p>
            </div>
            <div className={classNames('modal-footer')}>
              <div
                className={classNames('btn btn-primary')}
                role="button"
                tabIndex={0}
                onClick={() => this.handleCloseModal()}
                onKeyPress={() => this.handleCloseModal()}
              >
                {texts.it.close}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    modal: state.modal,
    session: state.session,
  }
}

export default connect(mapStateToProps, actionCreators)(ModalComponent)
