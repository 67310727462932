import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import './styles.scss'

import texts from '../../../../translations/texts.json'
import endExamTrigger from '../../../../common/enums/end_exam_trigger_map'

const classNames = require('classnames')

export class ReturnedExamComponent extends React.Component {
  componentDidMount = async () => {}

  render() {
    const { session } = this.props
    const { end_exam_trigger } = session

    return (
      <div className={classNames('')}>
        <div
          className={classNames(
            'start_exam container-fluid px-0 position-absolute top-50 d-flex flex-column justify-content-center align-items-center'
          )}
        >
          <div
            className={classNames('mx-2 mx-sm-5 text-center')}
            style={{ maxWidth: '305px' }}
          >
            {end_exam_trigger === endExamTrigger.timer
              ? texts.it.timeout_message
              : texts.it.exam_returned_message}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    session: state.session,
  }
}

export default connect(mapStateToProps, actionCreators)(ReturnedExamComponent)
