import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../../store/actions'
import { GenericComponent } from '../generic_component'
import './styles.scss'

const classNames = require('classnames')

export class GenericFullTextComponent extends GenericComponent {
  render() {
    const { data } = this.props
    const { element } = data
    const { text, values, new_line } = element

    return (
      <>
        <span className={classNames('lh-lg')}>{text}</span>
        {values.length > 0 && this.renderValues()}
        {new_line === true && <br />}
      </>
    )
  }
}

const mapStateToProps = state => {
  return { session: state.session }
}

export default connect(
  mapStateToProps,
  actionCreators
)(GenericFullTextComponent)
