/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../store/actions'
import './styles.scss'

import CountDownComponent from './_parts/count_down'
import ExamDeliveryComponent from './_parts/exam_delivery'

const classNames = require('classnames')

export class HeaderComponent extends React.Component {
  componentDidMount = async () => {}

  render() {
    const { session } = this.props
    const { student, exam } = session
    const { first_name, last_name } = student
    const { name } = exam

    return (
      <div className={classNames({ header: true }, 'shadow-sm sticky-top')}>
        <div className={classNames('container')}>
          <div
            className={classNames(
              'row justify-content-center align-items-center py-3 gy-2 gx-0'
            )}
          >
            <div className={classNames('col-sm-4 d-flex flex-column')}>
              <div
                className={classNames(
                  'fw-bold d-flex justify-content-center justify-content-sm-start'
                )}
              >
                {`${first_name} ${last_name}`}
              </div>
              <div
                className={classNames(
                  'd-flex justify-content-center justify-content-sm-start'
                )}
              >
                {name}
              </div>
            </div>
            <div
              className={classNames(
                'col-sm-4 d-flex justify-content-center justify-content-sm-center'
              )}
            >
              <img
                className={classNames({ head_logo: true })}
                src="/logo.png"
                alt="logo"
              />
            </div>
            <div className={classNames('col-sm-4 d-flex flex-column')}>
              <div
                className={classNames(
                  'd-flex justify-content-center justify-content-sm-end'
                )}
              >
                <CountDownComponent />
              </div>
              <div
                className={classNames(
                  'd-flex justify-content-center justify-content-sm-end'
                )}
              >
                <ExamDeliveryComponent />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    session: state.session,
  }
}

export default connect(mapStateToProps, actionCreators)(HeaderComponent)
