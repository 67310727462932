import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../store/actions'
import './styles.scss'

import BlocksListComponent from './_parts/blocks_list'
import ReturnedExamComponent from './_parts/returned_exam'
import StartComponent from './_parts/start'

const classNames = require('classnames')

export class ExamComponent extends React.Component {
  componentDidMount = async () => {}

  render() {
    const { session } = this.props
    const { returned, blocks } = session

    return (
      <div className={classNames({ exam_component: true })}>
        {returned === false && blocks.length === 0 && <StartComponent />}
        {returned === false && blocks.length > 0 && <BlocksListComponent />}
        {returned === true && <ReturnedExamComponent />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    session: state.session,
  }
}

export default connect(mapStateToProps, actionCreators)(ExamComponent)
