import React from 'react'
import { connect } from 'react-redux'

import AuthenticationComponent from '../authentication'
import ExamComponent from '../exam'

import * as actionCreators from '../../store/actions'
import './styles.scss'

export class HomeComponent extends React.Component {
  componentDidMount = async () => {}

  render() {
    const { session } = this.props
    const { student } = session
    return (
      <>
        {Object.keys(student).length === 0 && <AuthenticationComponent />}
        {Object.keys(student).length > 0 && <ExamComponent />}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    session: state.session,
  }
}

export default connect(mapStateToProps, actionCreators)(HomeComponent)
