/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable react/no-danger */
import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'

import * as actionCreators from '../../../../store/actions'
import './styles.scss'

import texts from '../../../../translations/texts.json'

const classNames = require('classnames')

export class DynamicDataComponent extends React.Component {
  componentDidMount = async () => {}

  shouldComponentUpdate = nextProps => {
    const { block_idx } = this.props
    return block_idx !== nextProps.block_idx
  }

  loadDynamicComponent = type => {
    let dComp = null
    try {
      dComp = require(`./_parts/${type}`).default
    } catch (e) {
      console.log(e)
    }
    return dComp
  }

  render() {
    const { list, block_idx } = this.props
    return (
      <div
        className={classNames({ dynamic_data_component: true }, 'my-5 pb-5')}
      >
        {list && list.map((element, idx) => {
          const { type } = element
          const data = {
            element,
            block_idx,
            question_idx: idx,
          }

          const DynamicComponent = this.loadDynamicComponent(type)

          if (DynamicComponent) {
            return <DynamicComponent key={uuid()} data={data} />
          }
          return <div key={uuid()}>{texts.it.unknown_type}</div>
        })}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    session: state.session,
  }
}

export default connect(mapStateToProps, actionCreators)(DynamicDataComponent)
