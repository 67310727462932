import React from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'

import './styles.scss'
import * as actionCreators from '../store/actions'
import HeaderComponent from '../components/header'
import ModalComponent from '../components/modal'

const classNames = require('classnames')

export class Layout extends React.Component {
  render() {
    const { children, session } = this.props
    const { student } = session

    return (
      <>
        <Helmet
          title={process.env.title}
          meta={[
            {
              name: 'description',
              content: 'Oxford School Of English Frontend',
            },
            { name: 'keywords', content: 'Oxford, frontend' },
          ]}
        >
          <html lang="it" className={classNames({ root: true })} />
        </Helmet>

        {Object.keys(student).length > 0 && <HeaderComponent />}
        <div>
          {children}
          <ModalComponent />
        </div>
        <footer className={classNames('fixed-bottom')}>
          <div
            className={classNames(
              'row d-flex justify-content-center fs-6 py-2 px-0 mx-0 gy-0 gx-1'
            )}
          >
            <div
              className={classNames(
                'col-xs-12 col-sm-auto d-flex justify-content-center'
              )}
            >
              Oxford School of English S.R.L.
            </div>
            <div
              className={classNames(
                'col-xs-12 col-sm-auto d-flex justify-content-center'
              )}
            >
              <a
                href="https://oxfordschool.com/"
                target="_blank"
                rel="noreferrer"
              >
                oxfordschool.com
              </a>
            </div>
            <div
              className={classNames(
                'col-xs-12 col-sm-auto d-flex justify-content-center'
              )}
            >
              <a
                href="mailto:oxford@oxfordschool.com"
                target="_blank"
                rel="noreferrer"
              >
                oxford@oxfordschool.com
              </a>
            </div>
          </div>
        </footer>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    session: state.session,
  }
}

export default connect(mapStateToProps, actionCreators)(Layout)
