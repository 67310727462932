import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'

import * as actionCreators from '../../../../../../store/actions'
import './styles.scss'

import { GenericListItemComponent } from '../_base/generic_list_item'

const classNames = require('classnames')

export class SentenceWithSelectComponent extends GenericListItemComponent {
  renderValues = () => {
    const { data } = this.props
    const { element, block_idx, question_idx } = data
    const { values } = element

    return (
      <div className={classNames('')}>
        {values.map((val, idx) => {
          const { before_control, after_control, options, value } = val
          
          return (
            <span key={uuid()} className={classNames('me-1')}>
              <span>{before_control}</span>
              <select
                style={{ minWidth: '200px', textAlignLast: 'center' }}
                className={classNames(
                  'fw-bold bg-white mx-1 border-top-0 border-start-0 border-end-0 border-bottom rounded-0'
                )}
                onChange={e => {
                  this.handleValueChanged(
                    e.target.value,
                    idx,
                    question_idx,
                    block_idx
                  )
                }}
              >
                <option value="">SELECT</option>
                {options.map(opt => {
                  return (
                    <option key={uuid()} value={opt} selected={opt===value}>
                      {opt}
                    </option>
                  )
                })}
              </select>
              <span>{after_control}</span>
            </span>
          )
        })}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    session: state.session,
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(SentenceWithSelectComponent)
