import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import './styles.scss'

import texts from '../../../../translations/texts.json'
import BlockComponent from '../block'

const classNames = require('classnames')

export class BlocksListComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected_block: 0,
    }
  }

  componentDidMount = async () => {}

  render() {
    const { selected_block } = this.state
    const { session } = this.props
    const { blocks } = session

    return (
      <div className={classNames({ blocks_component: true }, 'container my-5')}>
        <ul className="nav nav-tabs nav-fill">
          {blocks.map((block, idx) => {
            return (
              <li key={block.id} className="nav-item">
                <div
                  className={classNames(
                    { active: selected_block === idx },
                    'nav-link'
                  )}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    this.setState({ selected_block: idx })
                  }}
                  onKeyPress={() => {
                    this.setState({ selected_block: idx })
                  }}
                >
                  {texts.it.question}
                  &nbsp;
                  {idx + 1}
                </div>
              </li>
            )
          })}
        </ul>
        <BlockComponent
          data={blocks[selected_block]}
          block_idx={selected_block}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    session: state.session,
  }
}

export default connect(mapStateToProps, actionCreators)(BlocksListComponent)
