import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../store/actions'
import './styles.scss'

import texts from '../../translations/texts.json'
import { httpPost } from '../../api/apiService'

const classNames = require('classnames')

const requestKeys = {
  exam_id: 'exam_id',
  exam_password: 'exam_password',
  student_email: 'student_email',
}

export class AuthenticationComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      exam_id: '',
      exam_password: '',
      student_email: '',
    }
  }

  componentDidMount = async () => {}

  handleOnBlur = (e, key) => {
    const { value } = e.target
    this.setState({ [key]: value })
  }

  handleProcessLogin = async () => {
    // eslint-disable-next-line camelcase
    const { exam_id, exam_password, student_email } = this.state

    const result = await httpPost(`${process.env.apiUrl}login`, {
      exam_id,
      exam_password,
      student_email,
    })
    if (result) {
      const { status, data } = result
      if (status === 200) {
        const { session, updateSession } = this.props
        const { exam, student } = data
        const updatedSessionValue = { ...session }
        updatedSessionValue.exam = exam
        updatedSessionValue.student = student
        await updateSession(updatedSessionValue)
      } else {
        const { modal, updateModal } = this.props

        const duplicateModal = { ...modal }
        const title = 'Errore'
        const { message } = data
        duplicateModal.title = title
        duplicateModal.body = message
        duplicateModal.visible = true

        updateModal(duplicateModal)
      }
    }
  }

  render() {
    return (
      <div className={classNames({ authentication_component: true })}>
        <div className={classNames({ grid: true })}>
          <div className={classNames({ working_area: true })}>
            <div className={classNames({ logo: true })}>
              <img src="/logo.png" alt="logo" />
            </div>
            <div className={classNames({ form: true })}>
              <div className={classNames({ field: true })}>
                {texts.it.exam_code}
                <input
                  type="text"
                  defaultValue=""
                  onBlur={e => {
                    this.handleOnBlur(e, requestKeys.exam_id)
                  }}
                />
              </div>
              <div className={classNames({ field: true })}>
                {texts.it.email}
                <input
                  type="email"
                  defaultValue=""
                  onBlur={e => {
                    this.handleOnBlur(e, requestKeys.student_email)
                  }}
                />
              </div>
              <div className={classNames({ field: true })}>
                {texts.it.password}
                <input
                  type="password"
                  defaultValue=""
                  onBlur={e => {
                    this.handleOnBlur(e, requestKeys.exam_password)
                  }}
                />
              </div>
            </div>
            <div className={classNames({ actions: true })}>
              <div
                className={classNames('btn btn-primary')}
                role="button"
                tabIndex={0}
                onClick={() => this.handleProcessLogin()}
                onKeyPress={() => this.handleProcessLogin()}
              >
                {texts.it.enter}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    modal: state.modal,
    session: state.session,
  }
}

export default connect(mapStateToProps, actionCreators)(AuthenticationComponent)
