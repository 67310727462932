/* eslint-disable react/no-danger */
import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'

import * as actionCreators from '../../../../store/actions'
import './styles.scss'

const classNames = require('classnames')

export class StaticDataComponent extends React.Component {
  componentDidMount = async () => {}

  render() {
    const { list } = this.props
    return (
      <div className={classNames('row my-3 gx-3 gy-3')}>
        {list.map(element => {
          const { key, value } = element
          return (
            <div
              key={uuid()}
              className={classNames('col-12 col-md-6 d-flex flex-fill')}
            >
              <div
                className={classNames(
                  'shadow bg-white rounded p-3 d-flex flex-fill flex-column'
                )}
              >
                <div
                  className={classNames('fs-5 fw-bold border-bottom pb-1 mb-3')}
                >
                  {key}
                </div>
                <div dangerouslySetInnerHTML={{ __html: value }} />
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    session: state.session,
  }
}

export default connect(mapStateToProps, actionCreators)(StaticDataComponent)
