import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'

import * as actionCreators from '../../../../../../store/actions'
import { GenericListItemComponent } from '../_base/generic_list_item'
import './styles.scss'

const classNames = require('classnames')

export class BooleanComponent extends GenericListItemComponent {
  renderValues = () => {
    const { data } = this.props
    const { element, block_idx, question_idx } = data
    const { values } = element

    return (
      <div
        className={classNames('col-12 col-sm-6 d-flex justify-content-sm-end')}
      >
        {values.map((val, idx) => {
          const { key, value } = val
          const dynamicId = uuid()

          return (
            <div key={uuid()} className={classNames('form-check-inline')}>
              <input
                className={classNames('form-check-input')}
                type="radio"
                id={dynamicId}
                defaultChecked={value}
                name={`${block_idx}_${question_idx}`}
                onChange={e => {
                  this.handleValueChanged(
                    e.target.checked,
                    idx,
                    question_idx,
                    block_idx
                  )
                }}
              />
              <label
                className={classNames('form-check-label ms-2')}
                htmlFor={dynamicId}
              >
                {key}
              </label>
            </div>
          )
        })}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    session: state.session,
  }
}

export default connect(mapStateToProps, actionCreators)(BooleanComponent)
