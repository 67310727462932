/* eslint-disable react/no-danger */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import './styles.scss'

import texts from '../../../../translations/texts.json'
import StaticDataComponent from '../static_data'
import DynamicDataComponent from '../dynamic_data'

const classNames = require('classnames')

export class BlockComponent extends React.Component {
  componentDidMount = async () => {}

  render() {
    const { data, block_idx } = this.props
    const { section, part, info, static_data, dynamic_data } = data
    return (
      <div className={classNames({ exam_component: true })}>
        <div className={classNames('fs-5 py-2 mb-3 fw-bold')}>
          <div>{`${texts.it.section}: ${section}`}</div>
          <div>{`${texts.it.part}: ${part}`}</div>
        </div>
        <div
          className={classNames('text-break')}
          dangerouslySetInnerHTML={{ __html: info }}
        />
        {static_data.length > 0 && <StaticDataComponent list={static_data} />}
        <DynamicDataComponent list={dynamic_data} block_idx={block_idx} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    session: state.session,
  }
}

export default connect(mapStateToProps, actionCreators)(BlockComponent)
