import React from 'react'
import { connect } from 'react-redux'
import { updateSessionOnReduxAndSendToServer } from '../../../../common'

import * as actionCreators from '../../../../store/actions'
import './styles.scss'

import texts from '../../../../translations/texts.json'
import endExamTrigger from '../../../../common/enums/end_exam_trigger_map'

export class CountDownComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tick_milliseconds: 60000,
    }
  }

  componentDidMount = async () => {}

  componentDidUpdate = prevProps => {
    const { session } = this.props
    const { timing } = session

    const { session: prevSession } = prevProps
    const { timing: prevTiming } = prevSession

    if (
      Object.keys(prevTiming).length === 0 &&
      Object.keys(timing).length > 0
    ) {
      const { tick_milliseconds } = this.state
      setTimeout(() => {
        this.onTick()
      }, tick_milliseconds)
    }
  }

  onTick = () => {
    const { tick_milliseconds } = this.state
    const { session } = this.props

    const sessionCopy = { ...session }

    const { returned, timing } = sessionCopy
    const { remaining } = timing

    if (returned === true) return

    const updatedRemaining = remaining - 1

    timing.remaining = updatedRemaining

    if (updatedRemaining > 0) {
      setTimeout(() => {
        this.onTick()
      }, tick_milliseconds)
    } else {
      sessionCopy.end_exam_trigger = endExamTrigger.timer
      sessionCopy.returned = true
    }

    updateSessionOnReduxAndSendToServer(this.props, sessionCopy)
  }

  render() {
    const { session } = this.props
    const { timing, returned } = session
    return (
      <>
        {returned === false &&
          Object.keys(timing).length > 0 &&
          timing.countdown === true && (
            <>{`${texts.it.time}: ${timing.remaining} ${texts.it.minutes}`}</>
          )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    session: state.session,
  }
}

export default connect(mapStateToProps, actionCreators)(CountDownComponent)
