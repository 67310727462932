import React from 'react'
import { connect } from 'react-redux'
import { updateSessionOnReduxAndSendToServer } from '../../../../common'

import * as actionCreators from '../../../../store/actions'
import './styles.scss'

import texts from '../../../../translations/texts.json'
import endExamTrigger from '../../../../common/enums/end_exam_trigger_map'

const classNames = require('classnames')

export class ExamDeliveryComponent extends React.Component {
  componentDidMount = async () => {}

  handleDeliveryExam = () => {
    const { session } = this.props

    const sessionCopy = { ...session }
    sessionCopy.returned = true
    sessionCopy.end_exam_trigger = endExamTrigger.student
    updateSessionOnReduxAndSendToServer(this.props, sessionCopy)
  }

  render() {
    const { session } = this.props
    const { returned, blocks } = session
    return (
      <>
        {returned === false && blocks.length > 0 && (
          <div
            className={classNames('btn-lg btn-primary')}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.handleDeliveryExam()
            }}
            onKeyPress={() => {
              this.handleDeliveryExam()
            }}
          >
            {texts.it.delivery_exam}
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    session: state.session,
  }
}

export default connect(mapStateToProps, actionCreators)(ExamDeliveryComponent)
